import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { asyncScheduler, filter, Observable, take } from 'rxjs';
import { VerificationOptionEnum } from '@models/auth.model';
import { AsyncPipe, DecimalPipe, DOCUMENT } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { FormErrorMessagePipe } from '@pipes/form-error-message.pipe';
import { MatInput } from '@angular/material/input';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { CallStateEnum } from '@models/call-state.model';
import { DeviceStatusEnum } from '@models/device.model';
import { Router } from '@angular/router';
import { RoutePaths } from '@utils/route.utils';
import { TranslateModule } from '@ngx-translate/core';
import { countDownObservable } from '@app/pages/auth/features/data-access/device-verification.utils';
import { DeviceVerificationStepperEnum } from '@app/pages/auth/features/data-access/device-verification.model';
import { MatButton } from '@angular/material/button';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { AuthStore } from '@app/store/auth/auth.store';
import { FormConfigsStore } from '@app/store/form-configs/form-configs.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from '@services/auth.service';
import { WINDOW } from '@tokens/window-token';

@Component({
  selector: 'app-device-verification-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    DecimalPipe,
    MatLabel,
    MatError,
    MatFormField,
    FormErrorMessagePipe,
    MatInput,
    TranslateModule,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    NgxMaskDirective
  ],
  templateUrl: './device-verification-dialog.component.html',
  styleUrl: './device-verification-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideNgxMask()]
})
export class DeviceVerificationDialogComponent implements OnInit, OnDestroy {
  authStore = inject(AuthStore);
  formConfigsStore = inject(FormConfigsStore);
  resendCodeVerificationCallState$ = toObservable(
    this.authStore.resendCodeVerificationCallState
  );
  codeVerificationCallState$ = toObservable(
    this.authStore.codeVerificationCallState
  );
  changeDeviceStatusCallState$ = toObservable(
    this.authStore.changeDeviceStatusCallState
  );
  verificationCodeControl = new FormControl(null);
  step = signal(DeviceVerificationStepperEnum.One);
  stepCount = signal(Object.keys(DeviceVerificationStepperEnum).length / 2);
  codeExpirationTime = 30;
  deviceVerificationStepperEnum = DeviceVerificationStepperEnum;
  resendCodeCounter$: Observable<number>;
  errorMessage = {
    required: 'validation_device_security_code_required',
    pattern: 'validation_device_wrong_security_code',
    wrongSecurityCode: 'validation_device_wrong_security_code'
  };
  matDialogRef = inject(MatDialogRef<DeviceVerificationDialogComponent>);
  window = inject(WINDOW);
  authService = inject(AuthService);
  callStateEnum = CallStateEnum;
  deviceStatusEnum = DeviceStatusEnum;
  #router = inject(Router);
  contactPageUrl = RoutePaths.Core.Contact.absolutePath;
  verificationOptionEnum = VerificationOptionEnum;
  isDocumentClicked: WritableSignal<boolean> = signal(false);
  document = inject(DOCUMENT);

  ngOnInit() {
    const validators = [Validators.required];
    if (this.authStore.authUser().tfaType === VerificationOptionEnum.App) {
      validators.push(
        Validators.pattern(
          this.formConfigsStore.accountFormDataConfigs().deviceApp.validPattern
        )
      );
    } else {
      validators.push(
        Validators.pattern(
          this.formConfigsStore.accountFormDataConfigs().deviceEmail
            .validPattern
        )
      );
    }

    this.verificationCodeControl.setValidators(validators);
    this.verificationCodeControl.updateValueAndValidity({
      emitEvent: false
    });

    this.document.addEventListener('mousedown', this.onDocumentMouseDown);
  }

  ngOnDestroy() {
    this.document.removeEventListener('mousedown', this.onDocumentMouseDown);
  }

  onDocumentMouseDown = (event: MouseEvent) => {
    if (event.target?.['nodeName'] !== 'INPUT') {
      this.isDocumentClicked.set(true);
      this.document.removeEventListener('mousedown', this.onDocumentMouseDown);
    } else {
    }
  };

  onResendCode(inputElement: HTMLInputElement) {
    this.authStore.resendVerificationCode();
    this.verificationCodeControl.setValue('');
    this.verificationCodeControl.markAsUntouched();

    this.resendCodeVerificationCallState$
      .pipe(
        filter(
          (callState) =>
            callState !== CallStateEnum.Loading &&
            callState !== CallStateEnum.Init
        ),
        take(1)
      )
      .subscribe((callState) => {
        if (callState === CallStateEnum.Loaded) {
          this.resendCodeCounter$ = countDownObservable(
            this.codeExpirationTime
          );
          inputElement.focus();
        } else if (callState === CallStateEnum.Error) {
          this.matDialogRef.close();
        }
      });
  }

  onVerify() {
    this.verificationCodeControl.markAsTouched();

    if (this.verificationCodeControl.valid) {
      this.authStore.verifyCode({
        verificationCode: this.verificationCodeControl.value.trim(),
        tfaType: this.authStore.authUser().tfaType
      });

      this.codeVerificationCallState$
        .pipe(
          filter(
            (callState) =>
              callState !== CallStateEnum.Loading &&
              callState !== CallStateEnum.Init
          ),
          take(1)
        )
        .subscribe((callState) => {
          if (callState === CallStateEnum.Loaded) {
            this.step.set(DeviceVerificationStepperEnum.Two);
          } else if (callState === CallStateEnum.Error) {
            this.verificationCodeControl.setErrors({
              wrongSecurityCode: true
            });
          }
        });
    }
  }

  onChangeDeviceStatus(status: DeviceStatusEnum) {
    if (status === DeviceStatusEnum.VerifiedNotTrusted) {
      this.#router
        .navigate([RoutePaths.Core.My.Services.absolutePath])
        .then(() => {
          this.matDialogRef.close(true);
          asyncScheduler.schedule(() => this.window.scrollTo(0, 0), 200);
        });

      return;
    }

    this.authStore.changeDeviceStatus();
    this.changeDeviceStatusCallState$
      .pipe(
        filter((callState) => callState === CallStateEnum.Loaded),
        take(1)
      )
      .subscribe(() => {
        this.matDialogRef.close(true);
        asyncScheduler.schedule(() => this.window.scrollTo(0, 0), 200);
      });
  }

  onCancel() {
    this.authService.logout(true);
    this.matDialogRef.close(true);
  }
}
