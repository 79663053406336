import { CallStateEnum } from '@models/call-state.model';
import { AuthUserModel, LoggedInStateModel } from '@models/auth.model';
import { ITwoFAAuthenticationInfo } from '@app/pages/my/features/settings/data-access/models/account-settings.model';
import { RatesModel } from '@models/rates.model';

export interface IAuthData {
  authUser: AuthUserModel;
  loggedInState: LoggedInStateModel;
  impersonatedModerator: AuthUserModel;
  codeVerificationCallState: CallStateEnum;
  resendCodeVerificationCallState: CallStateEnum;
  resetPasswordCallState: CallStateEnum;
  changeDeviceStatusCallState: CallStateEnum;
  authUserCallState: CallStateEnum;
  signInCallState: CallStateEnum;
  createAccountCallState: CallStateEnum;
  logOutCallState: CallStateEnum;
  signInCallStateError: Error;
  exchangeRate: RatesModel;
  isExchangeRateLoading: boolean;

  tfaAccountInfo: ITwoFAAuthenticationInfo;
  tfaAccountInfoCallState: CallStateEnum;
  check2faVerificationCallState: CallStateEnum;
  updateDeviceStatusToVerifiedCallState: CallStateEnum;
}

export const AUTH_DATA_INITIAL_DATA: IAuthData = {
  authUser: null,
  loggedInState: null,
  impersonatedModerator: null,
  codeVerificationCallState: CallStateEnum.Init,
  resendCodeVerificationCallState: CallStateEnum.Init,
  resetPasswordCallState: CallStateEnum.Init,
  changeDeviceStatusCallState: CallStateEnum.Init,
  authUserCallState: CallStateEnum.Init,
  logOutCallState: CallStateEnum.Init,
  signInCallState: CallStateEnum.Init,
  createAccountCallState: CallStateEnum.Init,
  signInCallStateError: null,
  exchangeRate: null,
  isExchangeRateLoading: false,

  tfaAccountInfo: null,
  tfaAccountInfoCallState: CallStateEnum.Init,
  check2faVerificationCallState: CallStateEnum.Init,
  updateDeviceStatusToVerifiedCallState: CallStateEnum.Init
};
