import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { RatesModel } from '@models/rates.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  httpClient = inject(HttpClient);
  #baseUrl = `${environment.apiUrl}/payment`;

  getExchangeRate(): Observable<RatesModel> {
    const url = `${this.#baseUrl}/exchange-rate`;
    return this.httpClient.get<RatesModel>(url);
  }
}
